<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" :to="this.returnLink">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1><span>PO#{{this.data.poId}} - </span>{{' '+this.singular}} #{{this.data.id}}</h1>
<!--          <v-btn class="ml-2" fab x-small color="info" @click="openPrintDialog()"><v-icon>mdi-printer</v-icon></v-btn>-->
        </v-col>
        <v-col class="col-md-4">
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <span v-if="data.sealedBy">Sealed By: {{lookupUsername(data.sealedBy)}} at {{utils.formatDate(data.sealedAt)}}</span>
          <span>Created By: {{lookupUsername(data.createdBy)}} at {{utils.formatDate(data.createdAt)}}</span>
          <span v-if="data.voidedBy">Voided By: {{lookupUsername(data.voidedBy)}} at {{utils.formatDate(data.voidedAt)}}</span>
        </v-col>
      </v-row>
      <v-row v-if="!loader">
        <v-col cols="2" v-if="data.status!==1 && data.status!==-1">
          <h3>Products</h3>
          <div style="height:900px; overflow-y: scroll;" class="d-flex flex-column">
            <div v-for="(product, i) in po.PurchaseOrderLineItems" :key="i">
              <div style="padding: 10px; background-color:rgba(0,0,0,0.09); margin-bottom: 3px; border-radius: 7px;" class="d-flex flex-row align-center justify-space-between">
                <div>
                  <span>{{product.productName}}</span>
                  <br><span><b>ID:</b> {{product.productId}}</span>
                  <br><span><b>SKU:</b> {{product.sku}}</span>
                  <br><span><b>Rem. QTY: </b>{{product.remQuantity}}</span>
                </div>
                <v-btn :loading="product.loading" :disabled="data.ReceivingReportLineItems.findIndex(x => x.productId===product.productId)>=0 || product.remQuantity===0" @click="addItemToRR(i)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col>
          <v-card outlined class="pa-10">
            <div class="d-flex flex-row justify-space-between" v-if="po.id">
              <div class="d-flex flex-column" style="width: 50%; margin-bottom: 10px;">
                <div>
                  <span v-if="data.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
                  <span v-if="data.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
                  <span v-if="data.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
                </div>
                <h1 class="d-flex flex-row align-center">Receiving Report</h1>
              </div>
              <div class="d-flex flex-column align-start" style="width: 50%;">
                <h3>Supplier</h3>
                <span v-if="po.supplierInfo.name">{{po.supplierInfo.name}}</span>
                <span v-if="po.supplierInfo.address">{{po.supplierInfo.address}}</span>
                <span v-if="po.supplierInfo.phone">{{po.supplierInfo.phone}}</span>
                <span v-if="po.supplierInfo.email">{{po.supplierInfo.email}}</span>
              </div>
              <div class="d-flex flex-column align-start" style="width: 50%;">
                <h3>Ship To</h3>
                <span v-if="po.shipToInfo.name">{{po.shipToInfo.name}}</span>
                <span v-if="po.shipToInfo.address">{{po.shipToInfo.address}}</span>
                <span v-if="po.shipToInfo.phone">{{po.shipToInfo.phone}}</span>
                <span v-if="po.shipToInfo.email">{{po.shipToInfo.email}}</span>
              </div>
            </div>
            <v-card class="d-flex" :style="(selectedItem && selectedItem.productId===item.productId?'background: #eeeeee':'')" outlined v-for="(item, i) in data.ReceivingReportLineItems" :key="i">
              <v-col class="d-flex flex-column">
                {{item.name}}
                <span><b>ID:</b>{{item.productId}}</span>
                <span><b>SKU:</b>{{item.sku}}</span>
                <span><b>Rem. QTY: </b>{{po.PurchaseOrderLineItems[item.metadata.trueIndex].remQuantity}}</span>
              </v-col>
              <v-col class="d-flex">
                <v-text-field :min="1" :max="po.PurchaseOrderLineItems[item.metadata.trueIndex].remQuantity" @change="changeQty(i)" type="number" :rules="quantityRules" :disabled="!(data.status!==1 && data.status!==-1)" label="QTY" v-model="data.ReceivingReportLineItems[i].quantity"/>
                <v-btn
                    class="mx-2"
                    fab
                    x-small
                    dark
                    color="orange"
                    @click="showLocations(i)"
                    v-if="isManaged"
                >
                  <v-icon>mdi-warehouse</v-icon>
                </v-btn>
                <v-btn v-if="isManaged && (data.status!==1 && data.status!==-1 && item.quantity!==item.metadata.qtyToAdd)" style="font-size: 10px; width: 20px; height: 20px; margin-left: -20px; margin-top: -8px;" @click="showLocations(i)" fab x-small dark color="blue"><v-icon x-small>mdi-exclamation</v-icon></v-btn>
                <v-btn
                    v-if="data.status!==1 && data.status!==-1"
                    class="mx-2"
                    fab
                    x-small
                    dark
                    color="red"
                    :loading="item.loading"
                    @click="removeItemFromRR(i)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-card>
            <div v-if="data.ReceivingReportLineItems.length>0">
              <v-col>
                <v-text-field :disabled="!(data.status!==1 && data.status!==-1)" label="Notes" name="notes" v-model="data.metadata.notes"/>
              </v-col>
            </div>
            <div v-if="data.ReceivingReportLineItems.length>0 && data.status===0" style="margin-top: 20px; height: 100px;">
              <v-btn :disabled="data.status===1 || data.status===-1" style="margin-right:5px;" color="error" @click="deleteDraft">Delete Draft</v-btn>
              <v-btn :disabled="allLocationsSet===false || data.status===1 || data.status===-1" style="margin-right:5px;" @click="confirmSaveDialog=true" color="success">Save & Seal</v-btn>
              <v-btn v-if="data.status===1" @click="confirmVoidDialog=true" color="warning">Void Report</v-btn>
              <v-btn v-if="data.status===1" @click="openPrintBarcodeDialog()" color="info" style="margin-left: 5px;" >Print Barcodes</v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col cols="3" v-if="selectedItem">
          <h3>Locations for</h3>
          <span>{{selectedItem.name}}</span>
          <br>
          <b>ID:</b>{{selectedItem.productId}}
          <br>
          <b>SKU:</b>{{selectedItem.sku}}
          <br>
          <span v-if="data.status!==1 && data.status!==-1"><b>QTY to Add:</b>{{selectedItem.quantity-selectedItem.metadata.qtyToAdd}}</span>
          <br>
          <span class="red--text" v-if="this.selectedItem.metadata.qtyToAdd>=this.selectedItem.quantity">Total number of items to have been assigned locations.</span>
          <div style="height:900px; overflow-y: scroll;" class="d-flex flex-column">
            <div v-for="(location, i) in selectedItem.locations" :key="i" class="d-flex flex-row">
              <v-card outlined class="d-flex flex-row pt-2 px-5 mb-2" :style="(((data.status!==1 && data.status!==-1) && location.quantity>0)?'background: #eeeeee;':'')+'width: 100%;'">
                <v-text-field :min="0" @change="updateLocation(i, 0)" type="number" :rules="quantityRules" :disabled="data.status===1 || data.status===-1" :label="location.name+' QTY'" v-model="location.quantity"/>
                <v-btn v-if="data.status===0" fab small color="success" :disabled="selectedItem.metadata.qtyToAdd!==0" @click="updateLocation(i, selectedItem.quantity)"><v-icon>mdi-reply-all</v-icon></v-btn>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
        v-model="confirmSaveDialog"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Enter your password to confirm this change.
        </v-card-title>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="confirmSaveDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :disabled="!userPassword"
              @click="saveRR"
          >
            Save Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="confirmVoidDialog"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Enter your password to confirm this change.
        </v-card-title>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="confirmVoidDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :disabled="!userPassword"
              @click="voidRR"
          >
            Void Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="printDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print Barcodes
        </v-card-title>
        <v-card-text>
          <h3 style="margin: 0px;">Select item to print</h3>
          <hr>
          <v-select
            class="mt-2"
            :items="filteredPrinters"
            item-text="name"
            item-value="id"
            label="Select a Printer"
            v-model="printDialog.printer"
            outlined
          ></v-select>
          <v-text-field style="margin-top: 10px;" dense v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
          <authorizer 
            v-bind:buttontext="'Print'" 
            v-bind:small="true" 
            v-on:response="attemptPrint($event)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import authorizer from '../../components/authorizer.vue'
import utils from "../../plugins/helpers";
export default {
  components: {
    authorizer
  },
  data () {
    return {
      utils: utils,

      loader: false,
      printers: [],
      filteredPrinters: [],
      documentTypes: [],
      printDialog: {
        isOpen: false,
        quantity: 1,
        jobType: 'productLabel',
        deliveryNote: '',
        printer: '',
      },
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      returnLink: '/purchasing',
      max25chars: v => v.length <= 25 || 'Input too long!',
      data: {
        ReceivingReportLineItems : [],
        status: 0,
        poId: null,
        employeeId: null,
        metadata: {
          notes: ""
        }
      },
      lockPO: false,
      poID: "",
      po: {
        PurchaseOrderLineItems: []
      },

      quantityRules: [
        v => v>=0 || 'Must be more than or equal 0'
      ],

      allLocationsSet: false,

      pageLoading: false,

      locations: [],
      selectedItem: null,
      locationsNote: "",

      confirmSaveDialog: false,
      confirmVoidDialog: false,
      userPassword: "",
      singular: "Receiving Report",
      singularLower: "receivingreport",
      plural: "Receiving Reports",
      pluralLower: "receivingreports",
      printBarcodeDialog: {
        isOpen: false,
        password: '',
        adminId: ''
      }
    }
  },
  async mounted(){
    try{
      this.loader = true;
      if(this.$route.params.id){
        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error

        this.data = res.data.data;

        let data = await axios.get(`${this.getEndpoint}/api/locations/onlyRooms`)
        if(data.data.error) throw data.data.error
        this.locations = data.data.data;

        this.poID = this.data.poId;

        let po = await axios.get(`${this.getEndpoint}/api/purchaseorders/mergeReports/${this.poID}`)
        if(po.data.error) throw po.data.error

        this.po = po.data.data;

        if(!this.po.PurchaseOrderLineItems) throw "This PO has no items.";
        if(!this.po.shipToInfo) this.po.shipToInfo = {};
        this.data.poId = this.po.id;
        this.lockPO = true;

        for(let item of this.po.PurchaseOrderLineItems){
          item.loading = false;
        }

        this.returnLink = `/purchaseorders/view/${this.po.id}`;

        if(!this.data.ReceivingReportLineItems) this.data.ReceivingReportLineItems = [];

        this.data.ReceivingReportLineItems.forEach(item => {
          let poItem = this.po.PurchaseOrderLineItems.find(val => {
            return val.productId===item.productId;
          });

          poItem.hidden = true;
        });

        this.checkAllLocations();
        let userPrinters = this.getUser.metadata.printers
        let printers = await axios.get(`${this.getEndpoint}/api/devices/`)
        if(!printers.data.error){
          this.documentTypes = printers.data.data.documentTypes
          let printerDeviceTypeId = (printers.data.data.deviceTypes.filter(x=>x.name.toLowerCase()==='printer')[0]).id //looks at the deviceType object from the response to determine the id of the printers device type
          this.printers = printers.data.data.devices.filter(x=>x.deviceType===printerDeviceTypeId)
          this.printers.forEach(printer=>{
            for(let y in userPrinters?.default){
              if(printer.data.jobTypes.includes(y)){
                if(printer.id==userPrinters.default[y]){
                  printer['isDefault'] = true
                  printer.name = printer.name + ` (Default ${y} printer)`
                }else printer['isDefault'] = false
              }
            }
          })
          this.filteredPrinters = this.printers
        }
        return;
      }
      await this.$router.push({path: `/purchasing`})
    }
    catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }
    finally {
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters(['getId', 'getEndpoint', "isAllowed", "getUser", "isManaged", 'lookupUsername'])
  },
  methods: {
    openPrintDialog(){
      this.printDialog.isOpen = true
    },
    closePrintDialog(){
      this.printDialog = {
        isOpen: false,
        quantity: 1,
        jobType: '',
        deliveryNote: '',
        printer: ''
      }
    },
    async attemptPrint(e){
      try {
          if(!e.pin&&!e.vsidToken) throw "Error Getting Credentials"
          e.metadata = this.printDialog
          let res = await axios.post(`${this.getEndpoint}/api/print/productLabelsForRR/${this.$route.params.id}`, e)
          if(res.data.error) throw res.data.error
          this.closePrintDialog()
      } catch (error) {
        console.log(error)
        this.snack(error)
        this.closePrintDialog()
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    openPrintBarcodeDialog(){
      this.printBarcodeDialog.isOpen = true
    },
    cancelBarcodeDialog(){
      this.printBarcodeDialog.isOpen = false
      this.printBarcodeDialog.password = ""
    },
    async attemptBarcodePrint(){
      try {
        this.printBarcodeDialog.adminId = this.getId
        let res = await axios.post(`${this.getEndpoint}/api/products/printBarcodesForReceivingReport/${this.$route.params.id}`, this.printBarcodeDialog)
        if(res.data.error) throw res.data.error

        this.snack("Print Job Successfully Posted.")
        this.cancelBarcodeDialog()
      } catch (error) {
        console.log(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    createDefaultLocations(){
      let locations = [];

      this.locations.forEach(location => {
        let obj = {
          locationId: location.id,
          name: location.name,
          quantity: 0
        }

        locations.push(obj);
      });

      return locations;
    },
    showLocations(i){
      this.selectedItem = this.data.ReceivingReportLineItems[i];
    },
    async addProduct(i){
      let newLocations = this.createDefaultLocations();

      let item = {
        rrId: this.data.id,
        productId: this.po.PurchaseOrderLineItems[i].productId,
        name: this.po.PurchaseOrderLineItems[i].productName,
        sku: this.po.PurchaseOrderLineItems[i].sku,
        locations: newLocations,
        quantity: 1,
        metadata: {
          maxQuantity: this.po.PurchaseOrderLineItems[i].quantity,
          qtyToAdd: 0,
          trueIndex: i
        }
      };

      this.data.ReceivingReportLineItems.push(item);

      this.po.PurchaseOrderLineItems[i].hidden = true;

      await this.saveDraft();
    },
    async removeProduct(i){
      this.po.PurchaseOrderLineItems[this.data.ReceivingReportLineItems[i].metadata.trueIndex].hidden = false;
      if(this.selectedItem && this.selectedItem.productId===this.data.ReceivingReportLineItems[i].productId){
        this.selectedItem = null;
      }
      this.data.ReceivingReportLineItems.splice(i, 1);

      await this.saveDraft()
    },
    async changeQty(i){
      this.data.ReceivingReportLineItems[i].quantity=parseInt(this.data.ReceivingReportLineItems[i].quantity);
      this.data.ReceivingReportLineItems[i].locations = this.createDefaultLocations();
      this.data.ReceivingReportLineItems[i].metadata.qtyToAdd = 0;

      if(!this.data.ReceivingReportLineItems[i].quantity){
        this.data.ReceivingReportLineItems[i].quantity=1;
        this.snack("Quantity field cannot be empty.")
      }

      if(this.data.ReceivingReportLineItems[i].quantity<1){
        this.data.ReceivingReportLineItems[i].quantity=1;
        this.snack("Quantity must be at least 1.")
      }
      if(this.data.ReceivingReportLineItems[i].quantity>this.po.PurchaseOrderLineItems[this.data.ReceivingReportLineItems[i].metadata.trueIndex].remQuantity){
        this.data.ReceivingReportLineItems[i].quantity=parseInt(this.po.PurchaseOrderLineItems[this.data.ReceivingReportLineItems[i].metadata.trueIndex].remQuantity);
        this.snack(`Quantity cannot surpass the remaining ordered amount. (MAX: ${this.po.PurchaseOrderLineItems[this.data.ReceivingReportLineItems[i].metadata.trueIndex].remQuantity})`)
      }

      this.checkAllLocations();
      await this.updateItemFromRR(i)
      //this.saveDraft();
    },
    async updateLocation(i, qty){
      if(qty===0){
        this.selectedItem.locations[i].quantity = parseInt(this.selectedItem.locations[i].quantity);

        let total = 0;
        for(let location of this.selectedItem.locations){
          total+=location.quantity;
        }

        this.selectedItem.metadata.qtyToAdd = total;
        if(total>this.selectedItem.quantity){
          let without = this.selectedItem.quantity-(total-this.selectedItem.locations[i].quantity)
          this.selectedItem.metadata.qtyToAdd=this.selectedItem.quantity;
          this.selectedItem.locations[i].quantity = without;
          this.snack("Total surpassed. Quantity adjusted.")
        }
      }
      else{
        if(this.selectedItem.metadata.qtyToAdd+qty>this.selectedItem.quantity || this.selectedItem.locations[i].quantity+qty<0) return;
  
        this.selectedItem.locations[i].quantity=parseInt(this.selectedItem.locations[i].quantity)+qty;
        this.selectedItem.metadata.qtyToAdd=parseInt(this.selectedItem.metadata.qtyToAdd)+qty;
      }

      this.checkAllLocations();

      let index = this.data.ReceivingReportLineItems.findIndex(x => x.id===this.selectedItem.id);
      await this.updateItemFromRR(index)
      //this.saveDraft();
    },
    checkAllLocations(){
      if(this.isManaged){
        for(let item of this.data.ReceivingReportLineItems){
          if(item.quantity!==item.metadata.qtyToAdd){
            this.allLocationsSet = false;
            return;
          }
        }
      }
      this.allLocationsSet = true;
    },
    async fetchPO(){
      try{
        this.pageLoading = true;
        if(!this.poID) return;
        let res = await axios.get(`${this.getEndpoint}/api/purchaseorders/${this.poID}`)
        if(res.data.error) throw res.data.error

        if(res.data.status!==1) throw `PO#${this.poID} is not sealed.`

        this.po = res.data.data;
        this.data.poId = this.po.id;
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
        await this.$router.push({ path: this.returnLink});
      }
      finally{
        this.pageLoading = false;
      }
    },
    async saveDraft(){
      try{
        this.pageLoading = true;

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.data.id}`, this.data)
        if(res.data.error) throw res.data.error
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally{
        this.pageLoading = false;
      }
    },
    async deleteDraft(){
      try {
        this.data.employeeId = this.getId;
        let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.data.id}`)
        if (res.data.error) throw res.data.error

        this.snack(`Report deleted.`, "success")
        await this.$router.go(-1);
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async saveRR(){
      try{
        this.pageLoading = true;
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/save/${this.data.id}`, {password: this.userPassword})
        if(res.data.error) throw res.data.error

        this.data = res.data.data;
        if(this.selectedItem){
          this.selectedItem = this.data.ReceivingReportLineItems.find(x => {
            return this.selectedItem.productId === x.productId;
          });
        }
        this.snack(`Report sealed.`, "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.pageLoading = false;
        this.userPassword = "";
        this.confirmSaveDialog = false;
      }
    },
    async voidRR(){
      try{
        this.pageLoading = true;
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/void/${this.data.id}`, {password: this.userPassword})
        if(res.data.error) throw res.data.error

        this.data.status = -1;
        this.snack(`Report voided.`, "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.pageLoading = false;
        this.userPassword = "";
        this.confirmVoidDialog = false;
      }
    },
    parseStatus(id){
      if(id===-1) return "Voided"
      else if(id===0) return "Draft"
      else if(id===1) return "Sealed"
    },
    async addItemToRR(i){
      try {
        this.po.PurchaseOrderLineItems[i].loading = true;
        this.$forceUpdate();

        let newLocations = this.createDefaultLocations();

        let item = {
          rrId: this.data.id,
          productId: this.po.PurchaseOrderLineItems[i].productId,
          name: this.po.PurchaseOrderLineItems[i].productName,
          sku: this.po.PurchaseOrderLineItems[i].sku,
          locations: newLocations,
          quantity: 1,
          metadata: {
            maxQuantity: this.po.PurchaseOrderLineItems[i].quantity,
            qtyToAdd: 0,
            trueIndex: i
          }
        };

        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/addItem/${this.data.id}`, item)
        if (res.data.error) throw res.data.error

        this.data.ReceivingReportLineItems.push({...res.data, loading: false});

        this.snack("Item added.", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally{
        this.po.PurchaseOrderLineItems[i].loading = false;
        this.$forceUpdate()
      }
    },
    async removeItemFromRR(i){
      try {
        this.data.ReceivingReportLineItems[i].loading = true;
        this.$forceUpdate();

        let item = {
          rrId: this.data.id,
          productId: this.data.ReceivingReportLineItems[i].productId
        };

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/removeItem/${this.data.id}`, item)
        if (res.data.error) throw res.data.error

        this.data.ReceivingReportLineItems.splice(i, 1);

        this.snack("Item removed.", "success")
      }
      catch (error) {
        this.data.ReceivingReportLineItems[i].loading = false;
        this.$forceUpdate()
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async updateItemFromRR(i){
      try {
        console.log(i, this.data.ReceivingReportLineItems[i])
        this.data.ReceivingReportLineItems[i].loading = true;
        this.$forceUpdate();

        let item = {
          ...this.data.ReceivingReportLineItems[i]
        };

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateItem/${this.data.id}`, item)
        if (res.data.error) throw res.data.error

        this.data.ReceivingReportLineItems[i] = {
          ...res.data,
          loading: true
        };
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.data.ReceivingReportLineItems[i].loading = false;
        this.$forceUpdate()
      }
    },
  }
}
</script>
